import ng from 'angular';
import acpSpeechBubbleTemplate from './templates/acp-speech-bubble.html';

export class AcpSpeechBubbleComponentCtrl
  implements nsUtils.NsComponentController {
  public cta: () => void;
  public bottomSheet: boolean;

  constructor(
    private $document: ng.IDocumentService,
    private $timeout: ng.ITimeoutService
  ) {
    'ngInject';
  }

  public $onInit() {
    if (this.isBottomSheet()) {
      const elem = ng.element(
        this.$document[0].querySelectorAll('.bottom-sheet')[0]
      );
      this.$timeout(function() {
        elem.addClass('slide-up');
      }, 2000);
    }
  }

  public onClick() {
    this.cta();
  }

  public isBottomSheet() {
    return this.bottomSheet === true;
  }
}

export const acpSpeechBubbleComponent: ng.IComponentOptions = {
  controller: AcpSpeechBubbleComponentCtrl,
  controllerAs: 'vm',
  template: acpSpeechBubbleTemplate,
  bindings: {
    bottomSheet: '<?',
    message: '@',
    btnText: '@',
    cta: '&'
  }
};
